<template>
  <div>
    <div class="header">
      <img :src="logo" alt="" class="logo">
    </div>
    <section class="section1">
      <video poster="//biu-cn.dwstatic.com/zbshenqi/20210105/fcfac1545915274aae28c8213d411e52.png?w=1328&h=744" style="width: 100%" controls playsinline loop >
        <source src="//biu-cn.dwstatic.com/marki/20201106/416fb4f33633ba0d483a231518d25e3c.mp4" type="video/mp4">
      </video>
      <h2>Increase Field Work Management Efficiency with Marki</h2>
      <p>Works happens anywhere. You need an employee tracking app that can keep up.</p>
      <div v-if="isiOS" class="btn-list list1">
        <button class="btn" @click="toDownload"><i class="apple-store"></i>Download</button>
      </div>
      <div v-else class="btn-list list2">
        <button class="btn" @click="toDownload"><i class="google-play"></i>Google Play</button>
        <button class="btn" @click="toApkDownload"><i class="apk-download"></i>APK Download</button>
      </div>
    </section>
    <section class="section2">
      <img :src="m1" alt="" srcset="">
      <img :src="m2" alt="" srcset="">
      <img :src="m3" alt="" srcset="">
      <img :src="m4" alt="" srcset="">
    </section>
    <section class="section3">
      <h2>Optimize work process with simple mobile interface! TRY IT NOW!</h2>
      <div class="btn-list">
        <button class="btn" @click="toDownload"> <i class="iconfont iconapple"></i> App Store</button>
        <button class="btn" @click="toDownload"> <i class="iconfont icongoogle"></i> Google Play</button>
      </div>
      <h2>Contact Us</h2>
      <ul>
        <li>
          <i class="iconfont icondiqiu"></i>
          <a href="www.markicam.com">www.markicam.com</a>
        </li>
        <li>
          <i class="iconfont iconemail"></i>
          <a href="mailto:marki@vlight.sg">marki@vlight.sg</a>
        </li>
        <li>
          <i class="iconfont icondianhua"></i>
          <a href="tel:+65 85392176">+65 85392176</a>
        </li>
      </ul>
    </section>

    <div v-show="showBanner" class="download-banner">
      <img :src="logo3" class="logo3" alt="">
      <button class="btn" @click="toDownload">Download</button>
    </div>

    <img @touchend.prevent="scrollToTop('/partner')" class="partner-banner2" :src="partner" alt="">

  </div>
</template>

<script>
import isiOS from 'yypkg/is-ios'
import logo from '@images/logo.png'
import logo3 from '@images/logo3.png'
import m1 from '@images/m1.jpg'
import m2 from '@images/m2.jpg'
import m3 from '@images/m3.jpg'
import m4 from '@images/m4.jpg'
import partner from '@images/partner.png'
export default {
  data () {
    return {
      isiOS,
      logo,
      logo3,
      m1,
      m2,
      m3,
      m4,
      partner,
      showBanner: false
    }
  },
  methods: {
    scrollToTop (link) {
      window.scrollTo({
        top: 0
      })
      this.$router.push({ path: link })
    },
    toDownload () {
      window.copyTxt && window.copyTxt()
      window.location.href = 'https://marki.onelink.me/PyXh/44802bdb'
    },
    toApkDownload () {
      window.copyTxt && window.copyTxt()
      window.location.href = window.apkUrl
    },
    monitorScroll () {
      const self = this
      const topHeight = 300; const bottomHeigth = 300
      const pageHeight = document.body.offsetHeight || 2535
      function judge () {
        if (window.pageYOffset > topHeight && window.pageYOffset < (pageHeight - bottomHeigth)) {
          self.showBanner = true
        } else {
          self.showBanner = false
        }
      }
      judge()
      window.addEventListener('scroll', function name () {
        judge()
      })
    }
  },
  created () {
    this.monitorScroll()
  }
}
</script>

<style scoped lang="scss">
  img {
    user-select: none;
  }
  .download-banner {
    position: fixed;
    top: 0;
    z-index: 19;
    height: 1.2rem;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    align-items: center;
    padding: 0 20px 0 34px;
    .logo3 {
      width: 2.4rem;
      height: .62rem;
    }
    button {
      width: 2.26rem;
      height: .8rem;
      background: #2E5BFF;
      border-radius: .02px;
      text-align: center;
      font-size: .32rem;
      line-height: .8rem;
      color: #fff;
    }
  }
  .partner-banner2 {
    position: fixed;
    bottom: 0;
    z-index: 19;
    display: none;
  }
  .header {
    width: 100%;
    height: 1.3rem;
    text-align: center;
    line-height: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo {
      width: 1.58rem;
      height: 0.5rem;
    }
  }
  button {
    cursor: pointer;
    border: none;
    ::after {
      border: none;
      outline: none;
    }

  }

  .section1 {
    padding: 0 0.28rem 1.23rem;
    width: 100%;
    box-sizing: border-box;
    h2 {
      font-weight: bold;
      font-size: .56rem;
      line-height: 120%;
      margin-top: .46rem;
    }
    p {
      font-size: .32rem;
      line-height: 150%;
      margin-top: .12rem;
      margin-bottom: .46rem;
    }
    .btn-list {
      display: flex;
      justify-content: space-between;
      i {
        display: inline-block;
        background-size: contain;
        position: relative;
        margin-right: .24rem;
        background-repeat: no-repeat;
      }
      button {
        background: #232325;
        box-sizing: border-box;
        border-radius: 2px;
        color: #fff;
        line-height: 1.12rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.list1 button {
        width: 100%;
        height: 1.12rem;
        font-size: .4rem;
      }
      &.list2 button {
        width: 3.2rem;
        font-size: .32rem;
      }
    }
  }

  .section3 {
    padding: .8rem .22rem 1.1rem .4rem;
    box-sizing: border-box;
    background-color: #2E5BFF;
    color: #fff;
    h2 {
      font-size: .4rem;
      line-height: .64rem;
      margin-bottom: .2rem;
    }
    li {
      margin-bottom: .26rem;
      i {
        margin-right: .28rem;
        line-height: .44rem;
        position: relative;
        top: .03rem;
        font-size: .33rem;
      }
      a {
        color: #fff;
        font-size: .28rem;
        line-height: .44rem;
      }
    }
    .btn-list {
      display: flex;
      margin: .3rem 0 .56rem;
      button {
        width: 2.4rem;
        height: .8rem;
        color: #2E5BFF;
        background-color: #fff;
        margin-right: .26rem;
        border-radius: .06rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        transition-duration: 0.4s;
        i {
          margin-right: .1rem;
        }
      }

    }
  }

  .apple-store {
    width: .32rem;
    height: .39rem;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAxCAMAAABwO3AyAAAAOVBMVEUAAAD///////////////////////////////////////////////////////////////////////8KOjVvAAAAEnRSTlMAIO/fQL9gMBCfUIB/cM+vkI8Zqqg+AAAA+UlEQVRIx62U264DIQhFFbzPnf//2DOp9NC0STcPXW/OrGw0IuFH1BI9WltJJDnEQXJTsbjKA7eYoRhlUqDJU2QopilShOZptQH5kZiCy6Tuup5URvv2ez1yvkxp6bo/HNv7NZVdJrNsS5lE4RKMmOWF/eokr/D/nivJd7hqIhAtlQWyzMMAyy4BRw5tbkfpSXecxlm8q9jwLq1rAdVtxp+bVr1Cc7OnDchBIagmNXd36CmQ1Q7vUxsJ5ojWTAgrj7AxBGDrekAJzlB2d0mx0QofiNIYvjnfZLpFY0MNaixoMHyqdJYY6+isy2fi27ilvLWgjL4LL8/lHz1MUretQJ36AAAAAElFTkSuQmCC");
  }
  .google-play {
    width: .33rem;
    height: .33rem;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAMAAAApWqozAAAAz1BMVEUAAAAQz/8Q73gP8H76Pk36PUz5PkwN733/QFAO8X33PEz/yAj/ygr6Pk3/ygr/ygr3OFD/xwgP8H77UET/wA76PUwO8X7/yAnnSFoQzP//zxAfxfMQz/9C52ZG5G3/ygoP8H//ygn/yQn/ygsO8X3/yQr6ZDkQ5Kc16mr/ygsM73wQz/8Qz/8Qz//6Pk0P8H7/ygoQ1ueFhqYQ378P7oaidJAQ289omLw86GjwzBH7WEH/wQ/hzxj/uBI8tN1ZoceUfZst63DsR1jsYUv7T0XZmdnHAAAALXRSTlMAYCDf77+AYBCfQEDv39+fICDv7++Yf3BoUBDv39/Qz7+/r5CPgHBgYGBAMCCyvbV0AAABKklEQVQ4y43U11LDMBRFURFMje1AIKTQe3WhB0jo//9NxHcYtpFzJe/n9eAjeWSMOW23lxqmZlu3WZZt1OOXSVLo2YU6uJ+IrsdnEtHCL/wYnbFUxWi4jtEcjIrRLNWx9JDB3Rgt7TV8GC1LVYymo4aOpes7cXy6htHwMxs79McoPrewqoejPM/jpgOjh0+51FpVsWgsHGxrsRR3NSx6Wyy1wBW9E+ZWTRW/fr3Uxm+f6bOlD4yCH9NJ/3R4zMCKFb3+Zw8HBlyx0tqv3udSFCt6PKG7juu+SUt9j8OuITCWopOBsbBmg/lF++dXbQcKnm7nVjDgsoUue54CbNSzEBjLLjfGQlX8Xtrlw/fscmJsBFXqi5VdxttVYTkCT5uF7cguf70gkF3+fgAvzrKjdJEPKAAAAABJRU5ErkJggg==")
  }
  .apk-download {
    width: .36rem;
    height: .42rem;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAA0CAMAAADCKvr4AAAAP1BMVEUAAABlpw1mqwpkpw1ipw1kpw5kpw5kpg5kowxgnxBjpg5lqA1ipQ1jpQtkqA5kpwxjpg1lqA5kqA5kpg5kpw4croasAAAAFHRSTlMAnx+/YN/vgEAQcK9gMI9AUM/Pz8gfHgoAAAEOSURBVEjH7ZXdboMwDEYdG7vND9Btfv9nnZdEIEaTqL3qBefGgA5fTIRiOLIu8I9lhQ70gAOJoAcyHm5/ELoE8veFWIRpeYCboA+y6IbwIPwmeuTWkT3piW9sN/IXF2SPDvaEfUP/MsMBBK1EgGSFGn1r0Z1WqL4aWq0YMepGXMoGPWsn6VNa8dRwG91rGznbfurg4W1SjLMV18HS7y6mbbsnAO2AkOoPhOVjRjrnaotoZqTrpV/6m/r8mu5FDTfSSQ0Gi2dVwpGOZslcRoyVgZ4tD5WxnvlYndo2wxl0RCTlGCGDi2pXroa3lnDluNqDL/1V3WXntk9C6uqrGoJgeC5zosvESjNkkFQCHPgF6DFf2kB3ag0AAAAASUVORK5CYII=");
  }
</style>
