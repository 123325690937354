var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "header" }, [
      _c("img", { staticClass: "logo", attrs: { src: _vm.logo, alt: "" } })
    ]),
    _c("section", { staticClass: "section1" }, [
      _vm._m(0),
      _c("h2", [
        _vm._v("Increase Field Work Management Efficiency with Marki")
      ]),
      _c("p", [
        _vm._v(
          "Works happens anywhere. You need an employee tracking app that can keep up."
        )
      ]),
      _vm.isiOS
        ? _c("div", { staticClass: "btn-list list1" }, [
            _c(
              "button",
              { staticClass: "btn", on: { click: _vm.toDownload } },
              [_c("i", { staticClass: "apple-store" }), _vm._v("Download")]
            )
          ])
        : _c("div", { staticClass: "btn-list list2" }, [
            _c(
              "button",
              { staticClass: "btn", on: { click: _vm.toDownload } },
              [_c("i", { staticClass: "google-play" }), _vm._v("Google Play")]
            ),
            _c(
              "button",
              { staticClass: "btn", on: { click: _vm.toApkDownload } },
              [_c("i", { staticClass: "apk-download" }), _vm._v("APK Download")]
            )
          ])
    ]),
    _c("section", { staticClass: "section2" }, [
      _c("img", { attrs: { src: _vm.m1, alt: "", srcset: "" } }),
      _c("img", { attrs: { src: _vm.m2, alt: "", srcset: "" } }),
      _c("img", { attrs: { src: _vm.m3, alt: "", srcset: "" } }),
      _c("img", { attrs: { src: _vm.m4, alt: "", srcset: "" } })
    ]),
    _c("section", { staticClass: "section3" }, [
      _c("h2", [
        _vm._v(
          "Optimize work process with simple mobile interface! TRY IT NOW!"
        )
      ]),
      _c("div", { staticClass: "btn-list" }, [
        _c("button", { staticClass: "btn", on: { click: _vm.toDownload } }, [
          _c("i", { staticClass: "iconfont iconapple" }),
          _vm._v(" App Store")
        ]),
        _c("button", { staticClass: "btn", on: { click: _vm.toDownload } }, [
          _c("i", { staticClass: "iconfont icongoogle" }),
          _vm._v(" Google Play")
        ])
      ]),
      _c("h2", [_vm._v("Contact Us")]),
      _vm._m(1)
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showBanner,
            expression: "showBanner"
          }
        ],
        staticClass: "download-banner"
      },
      [
        _c("img", { staticClass: "logo3", attrs: { src: _vm.logo3, alt: "" } }),
        _c("button", { staticClass: "btn", on: { click: _vm.toDownload } }, [
          _vm._v("Download")
        ])
      ]
    ),
    _c("img", {
      staticClass: "partner-banner2",
      attrs: { src: _vm.partner, alt: "" },
      on: {
        touchend: function($event) {
          $event.preventDefault()
          return _vm.scrollToTop("/partner")
        }
      }
    })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "video",
      {
        staticStyle: { width: "100%" },
        attrs: {
          poster:
            "//biu-cn.dwstatic.com/zbshenqi/20210105/fcfac1545915274aae28c8213d411e52.png?w=1328&h=744",
          controls: "",
          playsinline: "",
          loop: ""
        }
      },
      [
        _c("source", {
          attrs: {
            src:
              "//biu-cn.dwstatic.com/marki/20201106/416fb4f33633ba0d483a231518d25e3c.mp4",
            type: "video/mp4"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("i", { staticClass: "iconfont icondiqiu" }),
        _c("a", { attrs: { href: "www.markicam.com" } }, [
          _vm._v("www.markicam.com")
        ])
      ]),
      _c("li", [
        _c("i", { staticClass: "iconfont iconemail" }),
        _c("a", { attrs: { href: "mailto:marki@vlight.sg" } }, [
          _vm._v("marki@vlight.sg")
        ])
      ]),
      _c("li", [
        _c("i", { staticClass: "iconfont icondianhua" }),
        _c("a", { attrs: { href: "tel:+65 85392176" } }, [
          _vm._v("+65 85392176")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }