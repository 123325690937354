var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer" }, [
    _c("div", { staticClass: "footer-main" }, [
      _vm._m(0),
      _c("div", { staticClass: "more" }, [
        _c("p", [_vm._v("More")]),
        _c("ul", [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/policy" } }, [
                _vm._v("Terms of Service")
              ])
            ],
            1
          ),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/policy" } }, [
                _vm._v("Privacy Policy")
              ])
            ],
            1
          )
        ])
      ]),
      _vm._m(1)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contact" }, [
      _c("p", [_vm._v("Contact Us")]),
      _c("ul", [
        _c("li", [
          _c("i", { staticClass: "iconfont iconemail" }),
          _c("a", { attrs: { href: "mailto:marki@vlight.sg" } }, [
            _vm._v("marki@vlight.sg")
          ])
        ]),
        _c("li", [
          _c("i", { staticClass: "iconfont icondianhua" }),
          _c("a", { attrs: { href: "tel:+65 85392176" } }, [
            _vm._v("+65 85392176")
          ])
        ]),
        _c("li", [
          _c("i", { staticClass: "iconfont icondiqiu" }),
          _c("a", { attrs: { href: "www.markicam.com" } }, [
            _vm._v("www.markicam.com")
          ])
        ])
      ]),
      _c("p", [
        _c("a", {
          staticClass: "icon fbb",
          attrs: { href: "https://www.facebook.com/markiapps" }
        }),
        _c("a", {
          staticClass: "icon yt",
          attrs: {
            href: "https://youtube.com/channel/UCgeA74hiI5BFFSbu1B2hnag"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "copyright" }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("@images/logo.png"), alt: "" }
      }),
      _c("p", [_vm._v("Copyright © 2020")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }