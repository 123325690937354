var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "case" },
    [
      _c("sction-title", {
        attrs: { title: "Who uses Marki for employee management?" }
      }),
      _c("div", { staticClass: "swiper-cover" }, [
        _c(
          "a",
          {
            staticStyle: { cursor: "pointer" },
            on: {
              click: function($event) {
                return _vm.scrollToTop(_vm.contentList[_vm.selected].link)
              }
            }
          },
          [
            _c("div", {
              staticClass: "swiper-img",
              style:
                "background-image: url(" +
                _vm.contentList[_vm.selected].bg +
                ")"
            })
          ]
        ),
        _c("div", { staticClass: "case-list" }, [
          _c("h4", [_vm._v("Case")]),
          _c(
            "ul",
            _vm._l(_vm.contentList, function(item, index) {
              return _c(
                "li",
                {
                  key: index,
                  class: _vm.selected === index ? "active" : "",
                  on: {
                    click: function($event) {
                      return _vm.setSelected(index)
                    }
                  }
                },
                [
                  _c("i", { class: "iconfont " + item.icon }),
                  _vm._v("\n\t\t\t\t\t\t" + _vm._s(item.case) + "\n\t\t\t\t\t")
                ]
              )
            }),
            0
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }